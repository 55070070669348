section.hero {
  display: flex;
  flex-direction: column;
}

section.hero h1, section.hero h2 {
  margin: 0;
}

section.hero h1 {
  font-size: 4.2rem;
}


.hero-top {
  min-height: 200px;
  display: flex;
  padding-bottom: 100px;
}

.hero-top-left {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2px;
  margin-bottom: 15vw;
}

.hero-logo {
  width: 15vw;
  padding-top: 50px;
}

.hero-water-surface {
  width: 100%; 
  position: relative;
  top: 5px;
}

.hero-water-main {
  min-height: 100px;
  background: #0BE0D3;
  position: relative;
  width: 100%;
}

.drifting-horizontally {
  -webkit-animation: drifting 7s infinite  alternate;
  animation: drifting 7s infinite  alternate;
}
@-webkit-keyframes drifting {
  0% { transform: translateX(0); }
  100% { transform: translateX(100px); }
}
@keyframes drifting {
  0% { transform: translateX(0); }
  100% { transform: translateX(100px); }
}

.ship-with-captain {
  overflow: hidden;
  width: 25%;
  top: -20vw;
  left: 60px;
  position: absolute;
  -webkit-animation: floating 1s infinite  alternate;
  animation: floating 1.5s infinite  alternate;
}

@-webkit-keyframes floating {
  0% { transform: translateY(0); }
  100% { transform: translateY(-5px); }
}
@keyframes floating {
  0% { transform: translateY(0); }
  100% { transform: translateY(-5px); }
}

.wave-wrapper {
  -webkit-animation: wave-horizontal 10s infinite  alternate;
  animation: wave-horizontal 10s infinite  alternate;
}
@-webkit-keyframes wave-horizontal {
  0% { transform: translateX(0); }
  100% { transform: translateX(-250px); }
}
@keyframes wave-horizontal {
  0% { transform: translateX(0); }
  100% { transform: translateX(-250px); }
}

.water-in-front-of-ship {
  position: relative;
  right: -80px;
  bottom: -23px;
  -webkit-animation: wave-vertical 2s infinite  alternate;
  animation: wave-vertical 2s infinite  alternate;
}

@-webkit-keyframes wave-vertical {
  0% { transform: translateY(0); }
  100% { transform: translateY(-5px); }
}
@keyframes wave-vertical {
  0% { transform: translateY(0); }
  100% { transform: translateY(-5px); }
}

.Hero-BG-wrapper {
  display: flex;
  flex-direction: column;
}

.hero-top-right {
  display: flex;
  flex: 1;
  padding: 2px;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  right: -1.5vw;
}

.hero-phone-shape {
  border: .5vw solid black;
  border-radius: 3vw;
  width: 25vw;
  height: 50vw;
  position: absolute;
  bottom: -25vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.hero-phone-cloud {
  position: relative;
  right: -15vw;
  top: 20px;
  width: 30%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.desktop-buttons {
  /* margin-top: -30px; */
  margin: 0 auto;
  width: 80%;
  /* width: 80%; */
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.phone-copy {
  padding: 30px 2vw 10px 2vw;
  text-align: center;
  flex: 2;
  font-size: 1.5vw;
}

.hero-phone-sky {
  flex: 9;
}

.hero-phone-bottom {
  flex: 5;
  background: #0BE0D3;
  width: 100%;
  left: 5;
  display: flex;
  justify-content: center;
}

.app-store-buttons {
  display: flex;
  align-items: center;
  padding: 5px;
}

.badge-wrapper {
  padding: 5px;
}

.App-store-badge {
  display: flex;
  max-width: 100%;
  cursor: pointer;
}

.hero-bottom {
  width: 100%;
  position: relative;
  top:-2px;
}

.mobile-only {
  display: none;
}

.mobile-only-logo-wrapper {
  display: none;
}

/*** Media Queries ***/
@media only screen and (max-width: 1000px) {
  .hero-phone-shape {
    bottom: -33vw;
  }
  .hero-phone-bottom {
    flex: 6;
  }
  .water-in-front-of-ship {
    bottom: -5px;
  }
  .app-store-buttons {
    flex-direction: column;
    /* flex: 4 */
  }
  .app-store-buttons {
    max-width: 60%;
  }

  .phone-copy {
    font-size: 14px;
  }
  @-webkit-keyframes wave-horizontal {
    0% { transform: translateX(0); }
    100% { transform: translateX(-200px); }
  }
  @keyframes wave-horizontal {
    0% { transform: translateX(0); }
    100% { transform: translateX(-200px); }
  }
  
}

@media only screen and (max-width: 675px) {
  .hero-top-left, .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .phone-copy {
    font-size: 3.8vw;
    padding: 0 3vw;
  }
  .mobile-only-logo-wrapper {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
  }
  .mobile-only-logo {
    width: 60px;
  }

  .hero-phone-shape {
    border: 1vw solid black;
    top: 10vw;
    width: 60vw;
    height: 120vw;
    max-height: 500px;
    border-radius: 6vw;
  }

  .hero-phone-cloud {
    display: none;
  }
  .hero-phone-sky {
    flex: 7;
  } 
  .ship-with-captain {
    left: 90;
    top: -18.5vw;
    padding-left: 13%;
  }
  @-webkit-keyframes wave-vertical {
    0% { transform: translateY(0); }
    100% { transform: translateY(-6px); }
  }
  @keyframes wave-vertical {
    0% { transform: translateY(0); }
    100% { transform: translateY(-6px); }
  }
}
