section.PAQ-section {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.birds-wrapper {
  z-index: 2;
  -webkit-animation: horizontalFlying 8s infinite  alternate;
  animation: horizontalFlying 8s infinite  alternate;
}
@-webkit-keyframes horizontalFlying {
  0% { transform: translateX(0); }
  100% { transform: translateX(80%); }
}
@keyframes horizontalFlying {
  0% { transform: translateX(0); }
  100% { transform: translateX(80%); }
}
.birds-rotation {
  -webkit-animation: rotation 10s infinite linear;
  animation: rotation 10s infinite linear;
}
@keyframes rotation {
  10% { transform: rotate(10deg); }
  40% { transform: rotate(-5deg); }
  70% { transform: rotate(25deg); }
  100% { transform: rotate(0deg); }
}

.birds-resizing {
  -webkit-animation: resize 9s infinite  alternate;
  animation: resize 9s infinite  alternate;
}
@-webkit-keyframes resize {
  0% { transform: scale(.6); }
  100% { transform: scale(1.1); }
}
@keyframes resize {
  0% { transform: scale(.6); }
  100% { transform: scale(1.1); }
}
.bird1 {
  -webkit-animation: verticalFlying 4.5s infinite  alternate;
  animation: verticalFlying 4.5s infinite  alternate;
}
@-webkit-keyframes verticalFlying {
  0% { transform: translateY(0); }
  100% { transform: translateY(-58px); }
}
@keyframes verticalFlying {
  0% { transform: translateY(0); }
  100% { transform: translateY(-58px); }
}
.bird2 {
  -webkit-animation: verticalFlying2 6s infinite  alternate;
  animation: verticalFlying2 6.5s infinite  alternate;
}
@-webkit-keyframes verticalFlying2 {
  0% { transform: translateY(0); }
  100% { transform: translateY(-38px); }
}
@keyframes verticalFlying2 {
  0% { transform: translateY(0); }
  100% { transform: translateY(-38px); }
}
.bird3 {
  -webkit-animation: verticalFlying3 5s infinite  alternate;
  animation: verticalFlying3 5.5s infinite  alternate;
}
@-webkit-keyframes verticalFlying3 {
  0% { transform: translateY(0); }
  100% { transform: translateY(-45px); }
}
@keyframes verticalFlying3 {
  0% { transform: translateY(0); }
  100% { transform: translateY(-45px); }
}

.PAQ-content {
  display: flex;
  flex: 1;
  background: #0BE0D3;
  justify-content: center;
}

.PAQ-top {
  width: 100%;
  position: relative;
  bottom: -2px;
  z-index: 3;
}

.white-box {
  width: 80%;
  background: white;
  margin-bottom: 200px;
  padding: 5vw;
}

.PAQ-title {
  font-size: 50px;
}

.PAQ-question {
  display: flex;
  cursor: pointer;
  margin-top: 30px;
}

.skull {
  margin-right: 15px;
}

.PAQ-answer {
  margin-top: 0;
  padding: 0 40px;
  font-size: 18px;
}

ul {
  list-style-type: none;
  padding: 0
}

details summary {
  font-size: 20px;
}
details > * {
  cursor: pointer;
  outline: none;
}


/*** Media Queries ***/
@media only screen and (max-width: 1000px) {
  .PAQ-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 675px) {
  .PAQ-question {
    font-size: 14px;
  }
  .PAQ-answer {
    font-size: 14px;
  }
}