section.how-to-play {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.title-wrapper {
  flex: 1;
  justify-content: center;
  font-size: 40px;
  margin-top: 50px;
  padding-top: 100px;
}
.how-to-cloud-wrapper {
  display: flex;
  position: relative;
  justify-content: flex-end;
  position: relative;
}
.how-to-play-cloud {
  width: 20%;
  top: -5vw;
  right: 8%;
  position: relative;
}

.big-number {
  position: relative;
  width: 45%;
  bottom: -100px;
  z-index: 1;
  max-height: 500px;
}
.number-one {
  width: 40%;
}

.how-to-step {
  padding: 20px 10%;
  display: flex;
  margin: 20px 0 8vw 0;
}

.how-to-text {
  text-align: center;
  z-index: 2;
  position: relative;
  top: -15vw;
  margin-left: 10vw;
  max-width: 300px;
  font-size: 18px;
}

.number-and-text {
  display: flex;
  flex-direction: column;;
  flex: 1;
  position: relative;
  top: -100px;
  max-height: 400px;
}

.how-to-image-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  max-height: 500px;
}

.how-to-image {
  width: 90%;
}

.mobile-only-step-image {
  display: none;
}

/*** Media Queries ***/
@media only screen and (max-width: 1000px) {
  .how-to-text {
    font-size: 16px;
  }
  .how-to-step {
    padding: 20px 5%;
  }

  .number-and-text {
    top: -40px;
  }
}

@media only screen and (max-width: 675px) {
  .title-wrapper {
    padding: 50px 25px 20px 25px
  }

  .how-to-text {
    font-size: 14px;
    margin-left: 0;
    max-width: 100%;
    top: -40vw;
  }

  .how-to-image-wrapper {
    display: none;
  }
  .mobile-only-step-image {
    display: flex;
    width: 100%;
    max-height: 150px;
  }
  .big-number {
    left: -20px;
  }
  .even-numbered-step {
    display: flex;
    justify-content: flex-end;
  }
  .even-numbered-step .big-number{
    left: 20px;
  }
}