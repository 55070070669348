main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body {
  background: #D9F1FF;
}

h1 {
  color: #323844;
  text-align: center;
  font-family: "Uni Sans", Verdana, Tahoma;
}

h2 {
  font-family: "Uni Sans", Verdana, Tahoma;
  color: #323844;
  font-size: 34px;
  text-align: center;
}

/* h3 {
  color: #199DFC;
  font-family: 'Unisans', Verdana, Tahoma;
  font-size: 34px;
  margin: 0;
  text-align: center;
} */


.main-area {
  padding: 50px;
}