section.footer {
  display: flex;
  background: #FFE8AA;
  flex-direction: column;
  flex: 1;
  padding: 50px 5%;
  color: #323844;
}

section.footer a {
  text-decoration: none;
}

ul {
  flex: 1;
  justify-content: flex-start;
  
}
ul a {
    color: black;
    font-family: "Unisans";
    font-size: 14px;
  }

  ul a:hover {
      color: #2fc7f7;
  }

.footer-bold-text-and-logo {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
/* .footer-bold-text-and-logo > *{
  cursor: pointer;
  font-size: 2vw;
  font-family: "Uni Sans", Verdana, Tahoma;
} */

.terms-and-privacy, .social-media {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-logo {
  max-width: 150px;
}

.footer-logo-wrapper h2 {
  font-size: 20px;
  margin: 0;
}

.footer-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-logo-mark {
  width: 55px;
}

.footer-bottom {
  display: flex;
}

.footer-made-with-text {
  flex: 1;
}

.footer-bottom-right-text {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
}
.footer-bottom-right-text > p {
  margin-bottom: 0;
}

.maple-leaf {
  width: 16px;
}


/*** Media Queries ***/
@media only screen and (max-width: 1000px) {
  .footer-bold-text-and-logo > *{
    font-size: 14px;
    padding: 5px;
  }
  .footer-logo {
    max-width: 110px;
  }
  .maple-leaf {
    width: 12px;
  }
}

@media only screen and (max-width: 675px) {
  .footer-bold-text-and-logo > *{
    font-size: 12px;
  }
  .footer-logo {
    max-width: 90px;
  }
  .footer-made-with-text, .footer-bottom-right-text {
    font-size: 10px;
  }
  .maple-leaf {
    width: 11px;
  }
}
